import React, { ReactElement, ReactNode } from 'react';
import { Chip } from '../../../components/Chips';
import { cx } from '../../../helpers/utils';

export default function PriceHeading(props: {
  isAnnual: boolean;
  annualAmount: number;
  monthlyAmount: number;
  currency: string;
  offerText: ReactNode;
}): ReactElement {
  const { isAnnual, annualAmount, monthlyAmount, currency } = props;
  const price = isAnnual ? annualAmount / 12 : monthlyAmount;

  const locale = Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
    roundingMode: 'ceil',
    trailingZeroDisplay: 'stripIfInteger',
  });

  const priceString = locale.format(price);
  const options = locale.resolvedOptions();

  // Used to check if the rendered string already contains the currency code
  const sign = locale
    .formatToParts(price)
    .find((x) => x.type === 'currency')?.value;

  const textSize =
    priceString.length >= 10
      ? 'text-2xl'
      : priceString.length >= 6
        ? 'text-3xl'
        : 'text-4xl';
  return (
    <div>
      <div className="text-slate-400 text-sm">
        {sign === options.currency ? '\u00A0' : options.currency}
      </div>
      <div className="no-wrap flex flex-row items-center gap-2">
        <div className={cx('font-bold leading-10', textSize)}>
          {priceString}
        </div>
        {isAnnual && <Chip color="red">{props.offerText}</Chip>}
      </div>
    </div>
  );
}
