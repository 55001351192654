import { MeetingLinkOrigin, generateMeetingLink, logger } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { MeetingReach } from '../../../graphql/operations';
import { trackTranscriptSharingStarted } from '../../../helpers/analytics';
import { baseURL } from '../../../helpers/firebase/config';
import { selectTeam } from '../../../redux/selectors';
import {
  ShareLinkViewSettings,
  fireShareCompletedAnalytics,
} from '../common/meeting-hooks';

export const useCopyMeetingLink = (options: {
  meetingId: string;
  fullSize: boolean;
  meetingReach?: MeetingReach;
}): {
  trigger: () => void;
} => {
  const { fullSize, meetingReach, meetingId } = options;
  const intl = useIntl();
  const team = useSelector(selectTeam);
  const link = generateMeetingLink(
    baseURL,
    { meetingId },
    MeetingLinkOrigin.SHARING_LINK
  );

  const onCopyLinkClick = useCallback(() => {
    trackTranscriptSharingStarted(
      fullSize
        ? 'Transcript Navbar - Copy Link'
        : 'Transcripts List - Copy Link',
      team?.id
    );

    window.navigator.clipboard.writeText(link).catch(logger.error);

    fireShareCompletedAnalytics({
      team_id: team?.id,
      type: 'Link',
      link_view_settings:
        meetingReach &&
        ({
          [MeetingReach.PUBLIC]: 'Everyone',
          [MeetingReach.RESTRICTED]: 'Restricted',
        }[meetingReach] as ShareLinkViewSettings),
      isSharingDetails: true,
      isSharingNotes: true,
      isSharingTranscript: true,
    });

    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage: 'Link copied to clipboard',
      }),
      { variant: 'SUCCESS' }
    );
  }, [fullSize, intl, link, meetingReach, team?.id]);

  return { trigger: onCopyLinkClick };
};
