import { saveAs } from 'file-saver';
import {
  Archive,
  ArchiveRestore,
  BookText,
  CalendarClock,
  Cloud,
  FileText,
  FileUp,
  Link,
  Mail,
  PencilLine,
  Share2,
  Trash2,
} from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TLoading } from '../../../components/buttons';
import { Menu } from '../../../components/Menu';
import { ModalDialog } from '../../../components/modals';
import { Spinner } from '../../../components/Spinner';
import { TextInput } from '../../../components/TextInput';
import { Tooltip } from '../../../components/Tooltip';
import {
  restoreArchivedMeeting,
  updateMeeting,
} from '../../../graphql/meetings';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../../helpers/analytics';
import { fetchApiV2 } from '../../../helpers/api/helpers';
import {
  isMeetingAdmin,
  isMeetingEditable,
  isMeetingOwner,
} from '../../../helpers/meetings';
import { toMarkdown } from '../../../helpers/transcript';
import { cx } from '../../../helpers/utils';
import { AnyMeeting } from '../../../models/meeting';
import {
  archiveMeeting as archiveMeetingAction,
  restoreMeeting,
} from '../../../redux/modules/global';
import {
  createSelectMeetingTagsSelector,
  createSelectTransformedTranscriptSelector,
  selectMeetingsMonthAllowance,
  selectTeam,
  selectTimestampOption,
  selectUid,
  selectUserTier,
} from '../../../redux/selectors';
import { RootState } from '../../../redux/store';
import { SpaceIcon } from '../../Common/icons';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { AddToSpaceMenu } from '../../Spaces/AddToSpaceMenu';
import { createStopPropagationHandler } from '../common/stopPropagation';
import { DeleteDialog } from './DeleteDialog';
import { isCurrent } from './helpers';
import { useArchiveMeeting } from '../actions/useArchiveMeeting';
import { useCopyMeetingLink } from '../actions/useCopyMeetingLink';
import { useDeleteMeeting } from '../actions/useDeleteMeeting';
import { useShareMeeting } from '../actions/useShareMeeting';
import { useShareMeetingByEmail } from '../actions/useShareMeetingByEmail';
import featureFlagService from '../../../helpers/feature-flags';

const ActionClasses =
  'hidden md:flex items-center flex-wrap justify-center md:justify-end min-w-[200px]';
interface Props {
  meeting: AnyMeeting;
  fullSize: boolean;
  className?: string;
  disabled?: boolean;
}

const AdaptiveButton = React.forwardRef<
  HTMLButtonElement,
  {
    id?: string;
    text: string | React.ReactElement;
    onClick: (event: React.SyntheticEvent) => void;
    fullSize: boolean;
    icon: React.ReactNode;
    loading?: boolean;
  }
>(function AdaptiveButton({ id, text, onClick, fullSize, icon, loading }, ref) {
  /**
   * if full size
   *  button visible up to sx
   *  icon visible at sx
   * else
   *  button not visible
   *  icon visible
   * end
   */

  if (fullSize) {
    return (
      <Button
        ref={ref}
        textSize="xs"
        size="small"
        variant="naked"
        onClick={onClick}
        startIcon={icon}
        loading={loading}
      >
        {text}
      </Button>
    );
  } else {
    return (
      <Tooltip title={text} placement="top">
        {/* Dropdown and Tooltip will not work together without this "div" */}
        <div>
          <Button id={id} ref={ref} onClick={onClick} variant="icon">
            <span>{loading ? <TLoading size="small" /> : icon}</span>
          </Button>
        </div>
      </Tooltip>
    );
  }
});

/**
 * Meeting Actionc
 * @param {unknown} props props
 * @returns {React.FC} a component
 */
export const MeetingActions: React.FC<Props> = (props) => {
  const {
    meeting,
    fullSize = false,
    className = ActionClasses,
    disabled,
  } = props;

  const userId = useSelector(selectUid);
  const isPreview = meeting.isPreview ?? false;
  const isArchived = !!meeting.archivedAt;
  const isEditable = isMeetingEditable(meeting);
  const isAdmin = isMeetingAdmin(meeting);
  const isOwner = userId && isMeetingOwner(userId, meeting);
  const isActiveUpload =
    typeof meeting.uploadProgress !== 'undefined' &&
    meeting.uploadProgress > 0 &&
    meeting.uploadProgress <= 100;
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setRenameDialogOpen] = useState(false);
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const [title, setTitle] = useState(meeting.title);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const monthAllowance = useSelector(selectMeetingsMonthAllowance);
  const intl = useIntl();
  const dispatch = useDispatch();

  const copyMeetingLink = useCopyMeetingLink({
    meetingId: meeting.id,
    meetingReach: meeting.permissions?.allow.reach,
    fullSize,
  });
  const shareMeetingByEmail = useShareMeetingByEmail({
    meetingId: meeting.id,
    fullSize,
    teamId: team?.id,
  });
  const shareMeeting = useShareMeeting({
    meetingId: meeting.id,
    fullSize,
    teamId: team?.id,
  });
  const meetingArchive = useArchiveMeeting({
    meetingIds: [meeting.id],
    fullSize,
    onCompleted: () => setDeleteDialogOpen(false),
  });
  const meetingDelete = useDeleteMeeting({
    meetingId: meeting.id,
    fullSize,
    onCompleted: () => setDeleteDialogOpen(false),
  });

  useEffect(() => {
    if (!isRenameDialogOpen) {
      setTitle(meeting.title);
    }
  }, [isRenameDialogOpen, meeting.title]);

  const onRestore = useCallback(async () => {
    trackWebEvent('Clicked Link - Restore Meeting');
    setRestoring(true);
    try {
      await restoreArchivedMeeting(meeting.id);
      dispatch(restoreMeeting(meeting.id));
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Your meeting was successfully restored',
        }),
        { variant: 'INFO' }
      );
    } catch (error) {
      dispatch(archiveMeetingAction(meeting.id));
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Unable to restore the meeting',
        }),
        { variant: 'ERROR' }
      );
    }
    setRestoring(false);
  }, [meeting, intl, dispatch]);

  const onRename = useCallback(
    async (value: string) => {
      const title = value.trim();
      setRenaming(true);
      await updateMeeting({ id: meeting.id, title });
      setRenaming(false);
      setRenameDialogOpen(false);
      if (meeting.title !== title) {
        trackWebEvent('Transcript Renamed', {
          old_name: meeting.title,
          new_name: title,
          team_id: team?.id,
        });
      }
    },
    [meeting.id, meeting.title, team?.id]
  );

  const selectTransformedTranscript = useMemo(
    createSelectTransformedTranscriptSelector,
    []
  );
  const transcript = useSelector((state: RootState) =>
    selectTransformedTranscript(state, meeting.id)
  );

  const selectMeetingTags = useMemo(createSelectMeetingTagsSelector, []);
  const tags = useSelector((state: RootState) =>
    selectMeetingTags(state, meeting.id)
  );

  const timestampOption = useSelector(selectTimestampOption);
  const screenshotUrls = useSelector(
    (state: RootState) => state.global.screenshotUrls
  );

  const onExport = useCallback(() => {
    if (!meeting || !transcript || !('user' in meeting)) {
      return;
    }

    trackWebEvent('Export to TXT button clicked');

    // ensure no empty blocks
    transcript.blocks = transcript.blocks.filter(
      (b) => b.transcript.trim().length > 0
    );

    const link = document.createElement('a');
    const mdcontent = toMarkdown(
      meeting,
      transcript,
      timestampOption,
      tags ?? [],
      screenshotUrls ?? {}
    );
    const file = new Blob([mdcontent], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = `${meeting.title}.txt`;
    link.click();
    URL.revokeObjectURL(link.href);
  }, [meeting, transcript, timestampOption, tags, screenshotUrls]);

  const onExportToTXT = useCallback(() => {
    trackWebEvent('Clicked Link - Export Meeting to TXT', {
      team_id: team?.id,
      place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
    });

    onExport();
  }, [fullSize, onExport]);

  const buttonClasses = featureFlagService.isNewMeetingListsEnabled()
    ? 'h-4 w-4 text-slate-600'
    : fullSize
      ? 'h-4 w-4 text-slate-600'
      : '';

  const exportButton = fullSize ? (
    <Menu>
      <Menu.Trigger>
        <AdaptiveButton
          text={
            <FormattedMessage
              defaultMessage="Export"
              description="Button label"
            />
          }
          fullSize
          onClick={() => {
            trackWebEvent('Clicked Link - Export', {
              place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
            });
          }}
          icon={
            exportInProgress ? (
              <Spinner />
            ) : (
              <FileUp className={buttonClasses} />
            )
          }
        />
      </Menu.Trigger>
      <Menu.Item
        onClick={async () => {
          if (exportInProgress) return;
          setExportInProgress(true);
          trackWebEvent('Clicked Link - Export Meeting to PDF', {
            team_id: team?.id,
            place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
          });

          const response = await fetchApiV2<Response>(
            `/a/meeting/${meeting.id}/pdf`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/pdf',
              },
              returnRaw: true,
            }
          );
          const blob = await response.blob();
          saveAs(blob, `${meeting.title}.pdf`);

          setExportInProgress(false);
        }}
        icon={<BookText className="h-4 w-4" />}
      >
        <FormattedMessage defaultMessage="Export to PDF" />
      </Menu.Item>
      <Menu.Item
        onClick={onExportToTXT}
        icon={<FileText className="h-4 w-4" />}
      >
        <FormattedMessage defaultMessage="Export to TXT" />
      </Menu.Item>
    </Menu>
  ) : null;

  const deleteButton =
    isOwner || isArchived ? (
      <>
        {disabled ? (
          <Tooltip
            placement="top"
            title={
              <FormattedMessage defaultMessage="This action is disabled when bulk editing" />
            }
          >
            <Button variant="icon" disabled>
              <Archive className={buttonClasses} />
            </Button>
          </Tooltip>
        ) : (
          <AdaptiveButton
            text={
              isArchived ? (
                <FormattedMessage
                  defaultMessage="Delete"
                  description="Button label"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Archive"
                  description="Button label"
                />
              )
            }
            fullSize={false}
            onClick={createStopPropagationHandler(() =>
              setDeleteDialogOpen(true)
            )}
            icon={
              isArchived ? (
                <Trash2 className={buttonClasses} />
              ) : (
                <Archive className={buttonClasses} />
              )
            }
          />
        )}

        {isDeleteDialogOpen ? (
          <DeleteDialog
            open
            onClose={() => setDeleteDialogOpen(false)}
            onDelete={() =>
              isArchived
                ? meetingDelete.trigger()
                : meetingArchive.trigger(isCurrent(meeting))
            }
            isArchived={isArchived}
            loading={meetingArchive.loading || meetingDelete.loading}
          />
        ) : null}
      </>
    ) : null;

  let content = null;

  if (isArchived) {
    content = (
      <>
        <AdaptiveButton
          text={
            <FormattedMessage
              defaultMessage="Restore"
              description="Button label"
            />
          }
          fullSize={fullSize}
          loading={restoring}
          onClick={createStopPropagationHandler(onRestore)}
          icon={<ArchiveRestore className={buttonClasses} />}
        />
        {deleteButton}
      </>
    );
  } else if (isActiveUpload) {
    content = null;
  } else if (isPreview && isOwner) {
    content = (
      <>
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="You have hit your free transcript limit for that period. Upgrade to access your full transcripts."
              description="Meeting action buttons. Preview icon tooltip text"
              values={{ monthAllowance }}
            />
          }
        >
          <Button
            color="error"
            variant="icon"
            onClick={createStopPropagationHandler(() => {
              setShowTierPricingDialog(true);

              trackWebEvent('Preview meeting card upgrade button clicked');
            })}
          >
            <CalendarClock />
          </Button>
        </Tooltip>
        {showTierPricingDialog && (
          <TierPricingDialog
            userTier={userTier}
            teamTier={team?.tier}
            source={TierPricingDialogSource.MEETING_ACTIONS}
            onClose={() => setShowTierPricingDialog(false)}
          />
        )}
        {deleteButton}
      </>
    );
  } else if (isPreview) {
    content = (
      <Tooltip
        title={
          <FormattedMessage
            defaultMessage="Owner of this meeting has hit the free transcript limit for that period. They need to upgrade to Pro plan to access the full transcript"
            description="Meeting action buttons. Preview icon tooltip text"
            values={{ monthAllowance }}
          />
        }
      >
        <Button color="error" variant="icon">
          <CalendarClock />
        </Button>
      </Tooltip>
    );
  } else if (isEditable) {
    const addToSpace = (
      <AddToSpaceMenu
        meetingId={meeting.id}
        activeSpaces={meeting.permissions?.allow.spaces ?? []}
        trigger={
          disabled ? (
            <Tooltip
              placement="top"
              title={
                <FormattedMessage defaultMessage="This action is disabled when bulk editing" />
              }
            >
              <Button variant="icon" disabled>
                <SpaceIcon className={buttonClasses} />
              </Button>
            </Tooltip>
          ) : (
            <AdaptiveButton
              text={<FormattedMessage defaultMessage="Add to space" />}
              fullSize={fullSize}
              onClick={() => {}}
              icon={<SpaceIcon className={buttonClasses} />}
            />
          )
        }
      />
    );

    const emailButton = disabled ? (
      <Tooltip
        placement="top"
        title={
          <FormattedMessage defaultMessage="This action is disabled when bulk editing" />
        }
      >
        <Button variant="icon" disabled>
          <Mail className={buttonClasses} />
        </Button>
      </Tooltip>
    ) : (
      <AdaptiveButton
        text={
          <FormattedMessage defaultMessage="Email" description="Button label" />
        }
        fullSize={fullSize}
        onClick={createStopPropagationHandler(shareMeetingByEmail.trigger)}
        icon={<Mail className={buttonClasses} />}
      />
    );
    content = (
      <>
        {addToSpace}
        {exportButton}
        {isAdmin && (
          <>
            {emailButton}
            {disabled ? (
              <Tooltip
                placement="top"
                title={
                  <FormattedMessage defaultMessage="This action is disabled when bulk editing" />
                }
              >
                <Button variant="icon" disabled>
                  <Link className={buttonClasses} />
                </Button>
              </Tooltip>
            ) : (
              <AdaptiveButton
                text={
                  <FormattedMessage
                    defaultMessage="Copy link"
                    description="Button label"
                  />
                }
                fullSize={fullSize}
                onClick={createStopPropagationHandler(copyMeetingLink.trigger)}
                icon={<Link className={buttonClasses} />}
              />
            )}
            {disabled ? (
              <Tooltip
                placement="top"
                title={
                  <FormattedMessage defaultMessage="This action is disabled when bulk editing" />
                }
              >
                <Button variant="icon" disabled>
                  <Share2 className={buttonClasses} />
                </Button>
              </Tooltip>
            ) : (
              <AdaptiveButton
                text={
                  <FormattedMessage
                    defaultMessage="Share"
                    description="Button label"
                  />
                }
                fullSize={fullSize}
                onClick={createStopPropagationHandler(shareMeeting.trigger)}
                icon={<Share2 className={buttonClasses} />}
              />
            )}
          </>
        )}

        {fullSize && (
          <AdaptiveButton
            text={
              <FormattedMessage
                defaultMessage="Rename"
                description="Button title"
              />
            }
            loading={renaming}
            fullSize={false}
            onClick={createStopPropagationHandler(() =>
              setRenameDialogOpen(true)
            )}
            icon={<PencilLine className={buttonClasses} />}
          />
        )}
        {isRenameDialogOpen ? (
          <ModalDialog
            open={isRenameDialogOpen}
            onClose={() => setRenameDialogOpen(false)}
            title={
              <FormattedMessage
                defaultMessage="Rename Meeting"
                description="Rename meeting dialog title."
              />
            }
            text={
              <div>
                <FormattedMessage
                  defaultMessage="This will rename the meeting in Tactiq and the transcript file in
            Google Drive."
                  description="Rename meeting dialog description"
                />
                <TextInput
                  autoFocus
                  label={
                    <FormattedMessage
                      defaultMessage="Meeting Title"
                      description="Rename meeting text field label."
                    />
                  }
                  onKeyDown={(event) => {
                    if (event.code === 'Enter') {
                      onRename(title).catch(() => {});
                    }
                  }}
                  value={title}
                  onChange={setTitle}
                />
              </div>
            }
            actions={
              <div className="flex flex-row gap-2">
                <Button onClick={() => setRenameDialogOpen(false)}>
                  <FormattedMessage
                    defaultMessage="Cancel"
                    description="Button label"
                  />
                </Button>
                <Button onClick={() => onRename(title)} loading={renaming}>
                  <FormattedMessage
                    defaultMessage="Rename"
                    description="Button label"
                  />
                </Button>
              </div>
            }
          />
        ) : null}
        {deleteButton}
      </>
    );
  } else {
    content = (
      <>
        {exportButton}
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="This meeting is shared with you by another Tactiq user. You don't have permissions to edit it."
              description="Meeting action buttons. Locked icon tooltip text"
              id="Z/UJOy"
            />
          }
        >
          <Button variant="icon">
            <Cloud />
          </Button>
        </Tooltip>
      </>
    );
  }

  if (featureFlagService.isNewMeetingListsEnabled()) {
    return <div className={cx('gap-1', className)}>{content}</div>;
  }

  return (
    <div className={cx(ActionClasses, fullSize ? 'gap-1' : 'flex-1 gap-2')}>
      {content}
    </div>
  );
};
