import { LabelConfig, LabelConfigStyleVariant } from '@tactiq/model';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../../../../components/ToggleButtonGroup';

const variantButtons = [
  {
    value: LabelConfigStyleVariant.outlined,
    label: <FormattedMessage defaultMessage="Outlined" />,
  },
  {
    value: LabelConfigStyleVariant.filled,
    label: <FormattedMessage defaultMessage="Filled" />,
  },
];

export const VariantPicker: React.FC<{
  color: LabelConfig['style']['color'];
  variant: LabelConfig['style']['variant'];
  onChange: (line: LabelConfig['style']['variant']) => void;
}> = ({ variant, onChange }) => {
  const onChangeLine = useCallback(
    (newVariant: LabelConfig['style']['variant']) => {
      onChange(newVariant);
    },
    [onChange]
  );

  return (
    <div>
      <label id="variant-select" className="mb-2 block font-semibold text-sm">
        <FormattedMessage
          defaultMessage="Variant"
          description="Label filter. Variant radio group label."
        />
      </label>

      <ToggleButtonGroup
        value={variant}
        aria-labelledby="variant-select"
        onChange={onChangeLine}
      >
        {variantButtons.map((button) => (
          <ToggleButton key={button.value} value={button.value}>
            {button.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
