import { LabelConfig, LabelConfigStyleColor } from '@tactiq/model';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../../../../components/ToggleButtonGroup';

const colorButtons = [
  {
    value: LabelConfigStyleColor.blue,
    label: 'Blue',
  },
  {
    value: LabelConfigStyleColor.purple,
    label: 'Purple',
  },
  {
    value: LabelConfigStyleColor.orange,
    label: 'Orange',
  },
  {
    value: LabelConfigStyleColor.green,
    label: 'Green',
  },
];

export const ColorPicker: React.FC<{
  color: LabelConfig['style']['color'];
  onChange: (color: LabelConfig['style']['color']) => void;
}> = ({ color, onChange }) => {
  const onChangeColor = useCallback(
    (newColor: LabelConfig['style']['color']) => {
      if (newColor) {
        onChange(newColor);
      }
    },
    [onChange]
  );

  return (
    <div>
      <label
        id="color-select-label"
        className="mb-2 block font-semibold text-sm"
      >
        <FormattedMessage
          defaultMessage="Color"
          description="Label filter. Color radio group label."
        />
      </label>
      <ToggleButtonGroup
        value={color}
        aria-labelledby="color-select-label"
        onChange={onChangeColor}
      >
        {colorButtons.map((button) => (
          <ToggleButton key={button.value} value={button.value}>
            {button.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
