import { useMutation } from '@apollo/client';
import { ModalDialog } from '../../../../components/modals';
import { CheckIcon, Layers as TeamTierIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../components/buttons';
import {
  createCheckoutSession_Team,
  isLocalhost,
} from '../../../../graphql/billing';
import {
  CreateCheckoutSession_Team_SwitchToPayByCardDocument,
  CreateTeamDocument,
  PreviewTeamUpgradeInvoiceDocument,
  RequestUpgradeDocument,
  TeamTier,
  UpgradeToTeamDocument,
  UserTier,
} from '../../../../graphql/operations';
import {
  KnownEventTypes,
  KnownEvents,
  TierPricingDialogSource,
  trackPricingWindowRequestUpgrade,
  trackPricingWindowUpgrade,
  trackWebEvent,
} from '../../../../helpers/analytics';
import { getCountryCode } from '../../../../helpers/locale';
import {
  selectTeam,
  selectUserPlan,
  selectUserPricing,
} from '../../../../redux/selectors';
import { RootState } from '../../../../redux/store';
import { PreviewInvoice } from '../../PreviewInvoice';
import { gotTeam } from '../../../../redux/modules/user';
import { upgradePlan } from '../../../../helpers/api/billing';
import PriceHeading from '../PriceHeading';

const PricingCardWrapperClasses =
  'h-full flex flex-col justify-start gap-5 rounded-2xl px-7 py-[21px] max-w-72 bg-white relative border-4 border-indigo-600';

/**
 *
 * @param {unknown} param0 params
 * @param {boolean} param0.isAnnual isAnnual
 * @param {UserTier} param0.userTier UserTier
 * @param {TeamTier | undefined} param0.teamTier Team Tier if available
 * @param {TierPricingDialogSource} param0.source Source of the dialog
 * @param {boolean} param0.isUsingPayPal isUsingPayPal
 * @param {string} param0.coupon Coupon code
 * @returns {React.ReactNode} Team Tier Card
 */
export const TeamTierCard: React.FC<{
  isAnnual: boolean;
  userTier: UserTier;
  teamTier: TeamTier | undefined;
  source: TierPricingDialogSource;
  isUsingPayPal: boolean;
  coupon?: string;
}> = ({ isAnnual, userTier, teamTier, source, isUsingPayPal, coupon }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [showSwitchToCardDialog, setShowSwitchToCardDialog] =
    React.useState<boolean>(false);
  const [isPreviewInvoiceOpen, setIsPreviewInvoiceOpen] =
    React.useState<boolean>(false);
  const userId = useSelector((state: RootState) => state.user.id);
  const team = useSelector(selectTeam);
  const userPlan = useSelector(selectUserPlan);
  const userPricing = useSelector(selectUserPricing);
  const [createTeam] = useMutation(CreateTeamDocument);
  const [upgradeToTeam] = useMutation(UpgradeToTeamDocument);
  const [previewTeamUpgradeInvoice] = useMutation(
    PreviewTeamUpgradeInvoiceDocument
  );
  const [requestUpgrade] = useMutation(RequestUpgradeDocument);
  const { team: teamPricing } = userPricing;
  const globalPricing = teamPricing;
  const countryCode = getCountryCode();
  const currency = globalPricing.currencies?.find(
    (c) => c.countryCode === countryCode
  );
  const pricing = React.useMemo(() => {
    if (currency) {
      return currency;
    }
    return globalPricing;
  }, [currency, globalPricing]);

  const [
    createCheckoutSession_Team_SwitchToPayByCard,
    createCheckoutSession_Team_SwitchToPayByCardMutation,
  ] = useMutation(CreateCheckoutSession_Team_SwitchToPayByCardDocument);

  const isAdmin =
    team?.members.some((m) => m.uid === userId && m.roles.ADMIN) ?? false;

  const featuresList = [
    intl.formatMessage({
      defaultMessage: 'Unlimited transcriptions',
    }),
    intl.formatMessage({
      defaultMessage: 'Unlimited AI credits',
    }),
    intl.formatMessage({
      defaultMessage: 'Team management',
    }),
  ];

  async function handleUpgradeClick() {
    if (!userId) return;

    trackPricingWindowUpgrade(
      userId,
      team?.id,
      source,
      isAnnual,
      userTier,
      teamTier,
      currency?.currency,
      'stripe'
    );

    if (!isProcessing) {
      try {
        setIsProcessing(true);
        if (!team) {
          await createTeam();
        }
        window.location.href = await createCheckoutSession_Team({
          isAnnual,
          coupon,
        });
      } finally {
        setIsProcessing(false);
      }
    }
  }

  return (
    <div className={PricingCardWrapperClasses}>
      <div className="-right-0.5 -top-0.5 absolute rounded-se-2xl rounded-es-lg bg-indigo-600 px-2 py-1 font-medium text-white text-xs">
        <FormattedMessage defaultMessage="Recommended" />
      </div>

      <div className="flex flex-col gap-2 font-semibold text-2xl text-slate-700">
        <TeamTierIcon className="h-7 w-7 text-orange-500" />
        <p>
          <FormattedMessage defaultMessage="Team" />
        </p>
      </div>
      {teamTier && teamTier !== TeamTier.FREE && !isAdmin ? (
        <>
          <div className="flex min-h-[68px] items-center justify-center rounded-lg bg-indigo-50 px-2 py-1 text-indigo-600">
            <p className="text-center">
              <FormattedMessage
                defaultMessage="Join the {amount} {others} already using your team's plan!"
                values={{
                  amount: <b>{team?.plan?.quantity}</b>,
                  others: (
                    <b>
                      <FormattedMessage defaultMessage="others" />
                    </b>
                  ),
                }}
              />
            </p>
          </div>
          <Button
            onClick={async () => {
              await requestUpgrade().then(() => {
                trackPricingWindowRequestUpgrade({
                  user_id: userId,
                  team_id: team?.id,
                  isAnnual,
                  userTier,
                  teamTier,
                  currency: currency?.currency ?? 'usd',
                });

                enqueueSnackbar(
                  intl.formatMessage({
                    defaultMessage:
                      'Your request has been sent to the team admin',
                  }),
                  {
                    variant: 'INFO',
                  }
                );
              });
            }}
            variant="filled"
            fullWidth
            size="large"
          >
            <FormattedMessage defaultMessage="Request upgrade" />
          </Button>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-1">
            <PriceHeading
              isAnnual={isAnnual}
              annualAmount={pricing.annualAmount}
              monthlyAmount={pricing.monthlyAmount}
              currency={currency?.currency ?? 'USD'}
              offerText={<FormattedMessage defaultMessage="offer" />}
            />

            <p className="text-slate-600 text-sm tracking-tighter">
              {isAnnual ? (
                <FormattedMessage defaultMessage="Per user, per month, billed annually" />
              ) : (
                <FormattedMessage defaultMessage="Per user, per month, billed monthly" />
              )}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <Button
              onClick={async () => {
                if (isUsingPayPal) {
                  // Switch to pay by card from PayPal before upgrading to team.
                  setShowSwitchToCardDialog(true);
                } else if (
                  // User is already on a paid pro plan and is using stripe. Show preview invoice.
                  // User is the team admin but not in a paid seat. Show preview invoice.
                  (userTier === UserTier.PRO &&
                    userPlan.paid?.__typename === 'StripePaidPlan') ||
                  (teamTier === TeamTier.TEAM && isAdmin)
                ) {
                  setIsPreviewInvoiceOpen(true);
                } else {
                  // Run our normal upgrade flow.
                  await handleUpgradeClick();
                }
              }}
              fullWidth
              variant="filled"
              size="large"
            >
              <FormattedMessage defaultMessage="Upgrade now" />
            </Button>
          </div>
        </>
      )}
      <div className="flex grow flex-col justify-end gap-4">
        <p className="font-semibold text-sm">
          <FormattedMessage defaultMessage="For Small & Medium Businesses" />
        </p>
        <div className="flex flex-col justify-center gap-2">
          {featuresList.map((feature, index) => (
            <span
              key={`${feature}_${index}`}
              className="flex flex-row items-center gap-2 text-slate-500"
            >
              <CheckIcon />
              <p className="text-sm">{feature}</p>
            </span>
          ))}
        </div>
      </div>
      {/* Preview invoice for when a user is already a paid pro user on stripe. */}
      <PreviewInvoice
        tag="team-upgrade"
        open={isPreviewInvoiceOpen}
        onClose={() => setIsPreviewInvoiceOpen(false)}
        description={
          <FormattedMessage defaultMessage="Upgrade your pro plan to a team plan" />
        }
        invoiceProducer={async function (approved: boolean) {
          if (approved) {
            await upgradePlan({
              uid: userId,
              isAnnual,
              isTeam: true,
              createTeam,
              upgradeToTeam,
              teamId: team?.id,
              memberId: userId,
              isMigratingExistingSubscription: true,
              coupon,
            });
          } else {
            if (!team) {
              const yourTeam = (await createTeam()).data?.createTeam;
              dispatch(gotTeam(yourTeam));
            }
            trackWebEvent(
              KnownEvents.PricingWindowUpgradeToTeamPreviewInvoice,
              {
                user_id: userId,
                team_id: team?.id,
                isAnnual,
                userTier,
                teamTier,
                source,
              } satisfies KnownEventTypes['PricingWindowUpgradeToTeamPreviewInvoice']
            );
            return (
              await previewTeamUpgradeInvoice({
                variables: {
                  input: {
                    quantity: 1,
                    forceAnnual: isAnnual,
                  },
                },
              })
            )?.data?.team_billing_previewUpgradeInvoice?.invoice;
          }
        }}
      />
      {showSwitchToCardDialog ? (
        // User is using PayPal and needs to switch to card before upgrading to team.
        <ModalDialog
          open
          title={
            <FormattedMessage defaultMessage="You're currently using PayPal" />
          }
          onClose={() => setShowSwitchToCardDialog(false)}
          text={
            <FormattedMessage defaultMessage="Tactiq team requires a pay by card subscription. Before you can switch to the team plan, you will be taken to the pay by card screen and then we will cancel your PayPal subscription." />
          }
          actions={
            <>
              <Button
                size="small"
                variant="filled"
                loading={
                  createCheckoutSession_Team_SwitchToPayByCardMutation.loading
                }
                onClick={async () => {
                  try {
                    const url = (
                      await createCheckoutSession_Team_SwitchToPayByCard({
                        variables: {
                          input: {
                            redirectToLocalhost: isLocalhost(),
                          },
                        },
                      })
                    ).data?.createCheckoutSession_Team_SwitchToPayByCard.url;

                    if (url) window.location.href = url;
                  } finally {
                    setShowSwitchToCardDialog(false);
                  }
                }}
              >
                <FormattedMessage defaultMessage="Get Tactiq Team" />
              </Button>
              <Button
                size="small"
                variant="neutral-secondary"
                onClick={() => setShowSwitchToCardDialog(false)}
              >
                <FormattedMessage defaultMessage="Go back" />
              </Button>
            </>
          }
        />
      ) : null}
    </div>
  );
};
