import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TLoading } from '../../components/buttons';
import { Checkbox } from '../../components/Checkbox';
import { Menu } from '../../components/Menu';
import { trackWebEvent } from '../../helpers/analytics';
import { showCreateSpaceDialog } from '../../redux/modules/global';
import { selectAddableSpaces } from '../../redux/selectors';
import { useUpdateMeetingSpaceStatus } from '../../services/Space';

type AddToSpaceMenuProps = {
  meetingId: string;
  activeSpaces: string[];
  trigger: React.ReactElement;
  onCompleted?: () => void;
};

export const AddToSpaceMenu: React.FC<AddToSpaceMenuProps> = ({
  meetingId,
  activeSpaces,
  trigger,
  onCompleted,
}) => {
  const dispatch = useDispatch();
  const addableSpaces = useSelector(selectAddableSpaces);
  const { updateMeetingSpaceStatus, loading } = useUpdateMeetingSpaceStatus();

  const addableSpacesSorted = [...addableSpaces].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const isInSpace = (spaceId: string) =>
    activeSpaces.some((id) => id === spaceId);

  return (
    <Menu>
      <Menu.Trigger>
        {loading ? (
          <div className="px-4">
            <TLoading />
          </div>
        ) : (
          trigger
        )}
      </Menu.Trigger>
      {addableSpacesSorted.map((space) => (
        <Menu.Item
          key={space.id}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Checkbox
            id={space.id}
            checked={isInSpace(space.id)}
            label={
              <div>
                {space.icon} {space.name}
              </div>
            }
            onChange={async (checked) => {
              await updateMeetingSpaceStatus(
                meetingId,
                space,
                checked,
                onCompleted
              );
            }}
            disabled={loading}
          />
        </Menu.Item>
      ))}
      {addableSpacesSorted.length > 0 && <Menu.Divider />}
      <Menu.Item
        key="create-space"
        onClick={(event) => {
          event.preventDefault();
        }}
      >
        <Button
          size="small"
          fullWidth
          onClick={() => {
            dispatch(showCreateSpaceDialog());
            trackWebEvent('Clicked Create Space Button', {
              source: 'meeting',
            });
          }}
        >
          <FormattedMessage defaultMessage="Create a space" />
        </Button>
      </Menu.Item>
    </Menu>
  );
};
