import React from 'react';
import { ModalDialog } from '../../components/modals';
import { Button } from '../../components/buttons';
import { FormattedMessage } from 'react-intl';
import { PayPal } from './PayPal';

import { PayByCard } from './PayByCard';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../helpers/analytics';
import { TeamTier, UserTier } from '../../graphql/operations';

/**
 *
 * @param {unknown} param0 params
 * @param {boolean} param0.open Open dialog
 * @param {() => void} param0.onClose onClose function
 * @param {boolean} param0.isAnnual is annual
 * @param {boolean} param0.isProcessing is processing
 * @param {() => void} param0.cardOnClick on card click handler
 * @param {TierPricingDialogSource} param0.source source of the dialog
 * @param {TeamTier} param0.teamTier team tier
 * @param {UserTier} param0.userTier user tier
 * @returns {React.ReactNode} Local price dialog
 */
export const LocalPriceDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  isAnnual: boolean;
  isProcessing: boolean;
  source: TierPricingDialogSource;
  teamTier: TeamTier | undefined;
  userTier: UserTier;
  cardOnClick: () => void;
}> = ({
  open,
  onClose,
  isAnnual,
  isProcessing,
  cardOnClick,
  source,
  teamTier,
  userTier,
}) => {
  React.useEffect(() => {
    if (open) {
      trackWebEvent('Local price dialog shown');
    }
  }, [open]);
  return (
    <ModalDialog
      open={open}
      onClose={() => {
        trackWebEvent('Local price dialog closed');
        onClose();
      }}
      title={<FormattedMessage defaultMessage="Want a local price?" />}
      text={
        <FormattedMessage defaultMessage="PayPal does not support local pricing. Choose the Pay by Card option to pay in local currency or continue to PayPal for USD pricing." />
      }
      actions={
        <>
          <Button
            variant="soft"
            onClick={() => {
              trackWebEvent('Local price dialog closed');
              onClose();
            }}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
        </>
      }
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <PayByCard
          isProcessing={isProcessing}
          onClick={() => {
            trackWebEvent('Local price dialog - Clicked pay by card');
            cardOnClick();
          }}
        />
        <PayPal
          source={source}
          teamTier={teamTier}
          userTier={userTier}
          isAnnual={isAnnual}
        />
      </div>
    </ModalDialog>
  );
};
