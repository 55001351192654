import { LabelConfig, MeetingLabelConfig } from '@tactiq/model';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { Menu } from '../../components/Menu';
import { addMeetingLabel } from '../../graphql/meetings';
import {
  trackMeetingLabelCreateButtonClicked,
  trackMeetingLabelMenuButtonClicked,
  trackMeetingLabelSelected,
} from '../../helpers/analytics';
import { selectLabels, selectTeam } from '../../redux/selectors';
import { createStopPropagationHandler } from '../Transcripts/common/stopPropagation';
import { LabelCreateDialog } from '../UserSettings/labels/Labels/CreateDialog';
import { Label } from './Label';
import { addLabelToMeeting } from '../../redux/modules/global';
import { Plus, PlusIcon } from 'lucide-react';
import { Tooltip } from '../../components/Tooltip';

export interface AddLabelButtonProps {
  meetingId: string;
  meetingLabels: MeetingLabelConfig[];
  small: boolean;
}

export const AddLabelButton: React.FC<AddLabelButtonProps> = ({
  meetingId,
  meetingLabels,
  small,
}) => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const labels = useSelector(selectLabels);
  const dispatch = useDispatch();
  const team = useSelector(selectTeam);
  const availableLabels = useMemo(() => {
    return labels.filter(
      (label) => !meetingLabels.some((m) => m.id === label.id)
    );
  }, [labels, meetingLabels]);

  const onCreateDialogOpen = createStopPropagationHandler(() => {
    trackMeetingLabelCreateButtonClicked();
    setIsCreateDialogOpen(true);
  });
  const onCreateDialogClose = () => setIsCreateDialogOpen(false);
  const onMenuOpen = () => trackMeetingLabelMenuButtonClicked();
  const onSelectLabel = async (label: LabelConfig) => {
    trackMeetingLabelSelected(team);
    dispatch(addLabelToMeeting({ meetingId, labelId: label.id }));
    await addMeetingLabel(meetingId, label.id);
  };

  return (
    <>
      <Tooltip
        placement="top"
        title={<FormattedMessage defaultMessage="Assign a label" />}
      >
        <Menu>
          <Menu.Trigger>
            <Button
              variant="naked"
              size="tiny"
              onClick={createStopPropagationHandler(onMenuOpen)}
              startIcon={<PlusIcon className="h-4 w-4" />}
            >
              {!small && <FormattedMessage defaultMessage="Add label" />}
            </Button>
          </Menu.Trigger>
          {availableLabels.map((label) => (
            <Menu.Item
              key={label.id}
              onClick={() => onSelectLabel(label)}
              className="min-h-8"
            >
              <Label {...label} />
            </Menu.Item>
          ))}
          <Menu.Divider />
          <Menu.Item
            icon={<Plus size="1rem" />}
            onClick={onCreateDialogOpen}
            className="min-h-8"
          >
            <FormattedMessage
              defaultMessage="Create new label"
              description="Create new label button. Tooltip text."
            />
          </Menu.Item>
        </Menu>
      </Tooltip>
      {isCreateDialogOpen ? (
        <LabelCreateDialog
          open={isCreateDialogOpen}
          onClose={onCreateDialogClose}
          labels={labels}
        />
      ) : null}
    </>
  );
};
