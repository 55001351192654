import {
  LabelConfig,
  LabelConfigStyleColor,
  LabelConfigStyleLine,
  LabelConfigStyleVariant,
} from '@tactiq/model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createLabel } from '../../../../graphql/labels';
import { trackWebEvent, trackWebPage } from '../../../../helpers/analytics';
import { addLabel } from '../../../../redux/modules/global';
import { LabelEditDialog } from './Form/Dialog';

/**
 * Create label dialog
 * @param {unknown} param0 params
 * @param {boolean} param0.open open
 * @param {LabelConfig[]} param0.labels existing labels
 * @param {() => void} param0.onClose close handler
 * @returns {React.FC} a component
 */
export const LabelCreateDialog: React.FC<{
  open: boolean;
  labels: LabelConfig[];
  onClose: () => void;
}> = ({ open, labels, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const defaultLabel = useMemo(
    () => ({
      id: '',
      name: 'New label',
      description: '',
      style: {
        color: LabelConfigStyleColor.blue,
        line: LabelConfigStyleLine.solid,
        variant: LabelConfigStyleVariant.outlined,
      },
      filters: [],
    }),
    []
  );

  useEffect(() => {
    if (open) {
      trackWebPage('Label create dialog');
    }
  }, [open]);

  const onCreate = useCallback(
    async (labelToCreate: LabelConfig) => {
      setIsLoading(true);

      trackWebEvent('Labels view - Create');

      const newLabel = await createLabel(labelToCreate);

      setIsLoading(false);

      if (newLabel) {
        dispatch(addLabel(newLabel));
      }

      onClose();
    },
    [dispatch, onClose]
  );

  return (
    <LabelEditDialog
      title={
        <FormattedMessage
          defaultMessage="Create new label"
          description="New label dialog. Dialog header."
        />
      }
      buttonText={
        <FormattedMessage
          defaultMessage="Create"
          id="Hqs+Lb"
          description="New label dialog. Create button title."
        />
      }
      defaultLabel={defaultLabel}
      open={open}
      showInfo
      existingLabelNames={labels.map((label) => label.name)}
      loading={isLoading}
      onSave={onCreate}
      onClose={onClose}
    />
  );
};
