import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button } from '../../../components/buttons';
import { trackWebEvent } from '../../../helpers/analytics';
import { GlobalLoadingClasses } from '../../Common/Loading';
import { cx } from '../../../helpers/utils';

const IconSpinnerClasses =
  'w-12 h-12 relative text-center cursor-wait rotate animate-skrotate';

export const DotClasses =
  'w-3/5 h-3/5 inline-block absolute top-0 rounded-full animate-skbounce';

export const Dot1Classes = cx(DotClasses, 'bg-[#8e52ff]');

export const Dot2Classes = cx(DotClasses, 'top-auto bottom-0 bg-[#a500c7]');

export const Dot3Classes = cx(DotClasses, 'top-auto left-0 bg-[#7500ca]');

/**
 *
 * @returns {React.ReactNode} Loading dots component
 */
export const Loading: React.FC = () => {
  return (
    <div className={IconSpinnerClasses}>
      <div className={Dot1Classes} />
      <div
        className={Dot2Classes}
        style={{ animationDelay: '-1s', WebkitAnimationDelay: '-1s' }}
      />
      <div
        className={Dot3Classes}
        style={{ animationDelay: '-500ms', WebkitAnimationDelay: '-500ms' }}
      />
    </div>
  );
};

const items = [
  'Loading Tactiq',
  'Signing you in',
  'Reaching through the internet',
  'Reading latest news',
  'Verifying weather forecast',
  'Hoping for the best',
  'Preparing the tools',
  'Writing more code',
  'Connecting to the server',
  'Loading resources',
];

function LoadingText() {
  const [text, setText] = useState(items[0]);
  const [started] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      const item = items[Math.floor(Math.random() * items.length)];

      setText(item);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const isTakingTooLong = Date.now() - started > 1000 * 20;

  return (
    <div className={LoadingTextContainerClasses}>
      {text}...
      {isTakingTooLong ? (
        <>
          <br />
          <br />
          Taking too long?
          <Button
            variant="soft"
            onClick={() => {
              trackWebEvent(
                'Clicked button - Try to log in again from the loading text'
              );
              window.location.href = window.location.origin + '/auth';
            }}
          >
            Try to log in again.
          </Button>
        </>
      ) : null}
    </div>
  );
}

export const LoadingComponent: React.FC<{ quiet?: boolean }> = ({ quiet }) => {
  return (
    <div className={GlobalLoadingClasses} data-testid="loading-component">
      {quiet ? null : <LoadingText />}
      <Loading />
    </div>
  );
};

const LoadingWrapperClasses = 'flex items-center justify-center w-full h-full';

const LoadingTextContainerClasses = 'mb-10 text-center';

export const LoadingContent: React.FC<
  PropsWithChildren<Record<string, never>>
> = ({ children }) => {
  return (
    <div className={LoadingWrapperClasses}>
      <div className={LoadingTextContainerClasses}>{children}</div>
      <Loading />
    </div>
  );
};

export default LoadingComponent;
