import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components/Alert';

export const LabelsInfo: React.FC = () => {
  return (
    <Alert
      severity="info"
      variant="light"
      description={
        <FormattedMessage
          defaultMessage="Create labels to group meetings together based on common properties."
          description="Labels info"
        />
      }
    />
  );
};
