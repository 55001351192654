import React from 'react';
import { CheckIcon } from 'lucide-react';
import { FormattedMessage, useIntl } from 'react-intl';
import FreeTierIcon from '../../../../img/FreeTierIcon.svg';
import {
  CurrentPlanComponent,
  CurrentUsageComponent,
  PricingCardWrapperClasses,
} from './CommonPieces';

/**
 *
 * @returns {React.ReactNode} Free Tier Card
 */
export const FreeTierCard: React.FC = () => {
  const intl = useIntl();

  const featuresList = [
    intl.formatMessage({
      defaultMessage: '10 transcriptions /mo',
    }),
    intl.formatMessage({
      defaultMessage: '5 AI credits /mo',
    }),
    intl.formatMessage({
      defaultMessage: 'Share & export transcripts',
    }),
  ];

  return (
    <div className={PricingCardWrapperClasses}>
      <div className="flex flex-col gap-2 font-semibold text-2xl text-slate-700">
        <img
          src={FreeTierIcon}
          className="h-7 w-7"
          alt="Free tier icon"
          aria-disabled
        />
        <p>
          <FormattedMessage defaultMessage="Free" />
        </p>
      </div>
      <CurrentPlanComponent />
      <CurrentUsageComponent />
      <div className="flex grow flex-col justify-end gap-4">
        <p className="font-semibold text-sm">
          <FormattedMessage
            defaultMessage="For getting started. Always free"
            id="597uD/"
          />
        </p>
        <div className="flex flex-col gap-2">
          {featuresList.map((feature, index) => (
            <span
              key={`${feature}_${index}`}
              className="flex flex-row items-center gap-2 text-slate-500"
            >
              <CheckIcon />
              <p className="text-sm">{feature}</p>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
