import React from 'react';
import amex from '../../img/amex.svg';
import jcb from '../../img/jcb.svg';
import mastercard from '../../img/mastercard.svg';
import visa from '../../img/visa.svg';
import { FormattedMessage } from 'react-intl';
import { cx } from '../../helpers/utils';

const buttonWrapperClasses =
  'box-border text-white mb-[7px] rounded-[23px] w-[420px] max-w-full h-[45px] flex py-3 px-[33px] whitespace-nowrap justify-center items-center hover:brightness-90';

export const PayByCard: React.FC<{
  isProcessing: boolean;
  onClick: () => void;
}> = ({ isProcessing, onClick }) => {
  return (
    <button
      className={cx(
        buttonWrapperClasses,
        isProcessing
          ? 'cursor-wait bg-slate-500'
          : 'cursor-pointer bg-indigo-500'
      )}
      onClick={onClick}
    >
      <div className="mr-4 flex h-4 flex-row gap-1">
        <img src={visa} alt="Visa" />
        <img src={mastercard} alt="Mastercard" />
        <img src={amex} alt="American Express" />
        <img src={jcb} alt="JCB" />
      </div>
      <FormattedMessage
        defaultMessage="Pay by Card"
        description="Pay by Card"
      />
    </button>
  );
};
